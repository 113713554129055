import { Module } from "vuex";

export const contactStore: Module<any, any> = {
  namespaced: true,
  state: {
    companyName: "Cunningham Piano Services Ltd",
    companyNumber: 4852073,
    iainPhoneNumber: "07850 328494",
    bradleyPhoneNumber: "07865 647473",
    emailAddress: "@cpianos.co.uk",
    bradleyEmailAddress: "bradleycunningham123@gmail.com",
    yearFound: 1981,
    socials: [
      {
        name: "Facebook",
        url: "https://www.facebook.com/Cpianos/",
        airaLabel: "View Facebook page",
      },
      {
        name: "Phone",
        url: "07850328494",
        airaLabel: "Get in touch",
      },
      {
        name: "Email",
        url: "Iain",
        airaLabel: "Enquire by email",
      },
    ],
    googleMapsInformation: {
      locationSrc:
          "pb=!1m14!1m8!1m3!1d9412.929912716763!2d-1.7676434!3d53.8565139!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe80766e2e4126712!2sCunningham%20Piano%20Services!5e0!3m2!1sen!2suk!4v1651251977107!5m2!1sen!2suk",
      width: 600,
      height: 450,
  }
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    companyName(state: any) {
      return state.companyName;
    },
    companyNumber(state: any) {
      return state.companyNumber;
    },
    iainPhoneNumber(state: any) {
      return state.iainPhoneNumber;
    },
    bradleyPhoneNumber(state: any) {
      return state.bradleyPhoneNumber;
    },
    emailAddress(state: any) {
      return state.emailAddress;
    },
    bradleyEmailAddress(state: any) {
      return state.bradleyEmailAddress;
    },
    socials(state: any) {
      return state.socials;
    },
    googleMapsInformation(state: any) {
      return state.googleMapsInformation;
    },
    yearFound(state: any) {
      return state.yearFound;
    },
  }
}