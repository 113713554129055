<template>
  <svg
    v-if="name === 'search'"
    aria-label="Search icon"
    x="0px"
    y="0px"
    viewBox="0 0 51 53"
  >
    <path
      d="M50.7,51.3L35.9,35.8C39.7,32,42,26.8,42,21C42,9.4,32.6,0,21,0S0,9.4,0,21s9.4,21,21,21c5.1,0,9.7-1.8,13.4-4.8l14.9,15.5
	c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3C51.1,52.3,51.1,51.7,50.7,51.3z M21,40C10.5,40,2,31.5,2,21S10.5,2,21,2
	s19,8.5,19,19S31.5,40,21,40z"
    ></path>
  </svg>

  <svg
    v-else-if="name === 'menu'"
    aria-label="Menu icon"
    x="0px"
    y="0px"
    viewBox="0 0 512 355.1"
  >
    <path
      d="M491.3,156.9H20.7C9.3,156.9,0,166.1,0,177.6s9.3,20.7,20.7,20.7h470.6c11.4,0,20.7-9.3,20.7-20.7 C512,166.1,502.7,156.9,491.3,156.9z"
    ></path>
    <path
      d="M491.3,0H20.7C9.3,0,0,9.3,0,20.7s9.3,20.7,20.7,20.7h470.6c11.4,0,20.7-9.3,20.7-20.7S502.7,0,491.3,0z"
    ></path>
    <path
      d="M491.3,313.8H20.7C9.3,313.8,0,323,0,334.4s9.3,20.7,20.7,20.7h470.6c11.4,0,20.7-9.3,20.7-20.7S502.7,313.8,491.3,313.8z "
    ></path>
  </svg>

  <svg
    v-else-if="name === 'facebook'"
    aria-label="Facebook icon"
    x="0px"
    y="0px"
    viewBox="0 0 12 24"
  >
    <path
      d="M3,8H0v4h3v12h5V12h3.64L12,8H8V6.33C8,5.38,8.19,5,9.11,5H12V0H8.19C4.6,0,3,1.58,3,4.61V8z"
    />
  </svg>

  <svg
    v-else-if="name === 'whatsapp'"
    aria-label="Facebook icon"
    x="0px"
    y="0px"
    viewBox="0 0 23.89 24"
  >
    <path
      d="M0,24l1.69-6.16c-1.04-1.8-1.59-3.85-1.59-5.95C0.1,5.33,5.44,0,11.99,0c3.18,0,6.17,1.24,8.41,3.49
	c2.25,2.25,3.48,5.24,3.48,8.41c0,6.56-5.34,11.89-11.89,11.89c-1.99,0-3.95-0.5-5.69-1.45C6.3,22.35,0,24,0,24z M6.6,20.19
	c1.68,1,3.28,1.59,5.39,1.59c5.45,0,9.89-4.43,9.89-9.89c0-5.46-4.42-9.89-9.88-9.89c-5.45,0-9.89,4.43-9.89,9.88
	c0,2.22,0.65,3.89,1.75,5.63l-1,3.65C2.86,21.17,6.6,20.19,6.6,20.19z M17.98,14.73c-0.07-0.12-0.27-0.2-0.57-0.35
	c-0.3-0.15-1.76-0.87-2.03-0.97c-0.27-0.1-0.47-0.15-0.67,0.15c-0.2,0.3-0.77,0.97-0.94,1.16s-0.35,0.22-0.64,0.07
	c-0.3-0.15-1.26-0.46-2.39-1.48c-0.88-0.79-1.48-1.76-1.65-2.06c-0.17-0.3-0.02-0.46,0.13-0.61c0.13-0.13,0.3-0.35,0.45-0.52
	c0.15-0.17,0.2-0.3,0.3-0.49c0.1-0.2,0.05-0.37-0.02-0.52C9.86,8.98,9.27,7.52,9.02,6.92c-0.24-0.58-0.49-0.5-0.67-0.51L7.78,6.4
	c-0.2,0-0.52,0.07-0.79,0.37S5.95,7.79,5.95,9.25s1.07,2.88,1.21,3.07c0.15,0.2,2.1,3.2,5.08,4.49c0.71,0.31,1.26,0.49,1.69,0.63
	c0.71,0.23,1.36,0.19,1.87,0.12c0.57-0.08,1.76-0.72,2.01-1.41C18.06,15.45,18.06,14.85,17.98,14.73z"
    />
  </svg>

  <svg
    v-else-if="name === 'phone'"
    aria-label="Phone icon"
    x="0px"
    y="0px"
    viewBox="0 0 34.33 34.39"
  >
    <path
      d="M20.62,8.97c2.4,0.47,4.27,2.34,4.74,4.74c0.16,0.81,0.95,1.34,1.76,1.18s1.34-0.95,1.18-1.76
		c-0.7-3.6-3.51-6.41-7.11-7.11c-0.81-0.16-1.6,0.37-1.76,1.18S19.81,8.81,20.62,8.97z M20.74,2.99c5.57,0.62,9.97,5.01,10.6,10.59
		c0.09,0.82,0.83,1.42,1.66,1.32c0.82-0.09,1.42-0.83,1.32-1.66C33.54,6.28,28.04,0.78,21.07,0.01c-0.82-0.09-1.57,0.5-1.66,1.33
		C19.33,2.16,19.92,2.9,20.74,2.99z M31.33,25.38h-1.5v4.5l0,0.02l0.66,0l-0.66,0v0l0.66,0l-0.66,0c0,0.82-0.67,1.5-1.49,1.5h0.01
		h-0.01l-0.14-0.01l-0.13,1.49l0.16-1.49c-4.38-0.47-8.59-1.97-12.29-4.37l-0.01-0.01c-3.44-2.18-6.35-5.1-8.54-8.54l-0.01-0.01
		C4.97,14.75,3.47,10.52,3,6.11L1.51,6.27l1.49-0.14L3,6c0-0.76,0.59-1.42,1.37-1.49L4.23,3.01l0.07,1.5L4.53,4.5L9,4.5l0.01,0
		l0.02,0c0.75,0,1.38,0.55,1.48,1.29l0-0.01c0.2,1.55,0.58,3.08,1.13,4.55l0,0c0.06,0.17,0.1,0.35,0.1,0.53
		c0,0.39-0.15,0.77-0.43,1.06l0.01-0.01l-1.9,1.9c-0.48,0.48-0.58,1.22-0.24,1.8c2.27,3.99,5.57,7.29,9.56,9.56
		c0.59,0.33,1.33,0.23,1.8-0.24l1.9-1.9l-0.01,0.01c0.29-0.28,0.67-0.43,1.06-0.43c0.18,0,0.35,0.03,0.53,0.1l0,0
		c1.47,0.55,2.99,0.93,4.54,1.13l-0.01,0c0.74,0.1,1.29,0.74,1.29,1.48l0,0.04l0,0.04H31.33l1.5,0.04l0-0.11
		c0-2.24-1.65-4.14-3.88-4.46l-0.01,0c-1.33-0.18-2.63-0.5-3.89-0.97l0,0c-0.51-0.19-1.05-0.29-1.58-0.29
		c-1.16,0-2.31,0.45-3.16,1.3l-0.01,0.01l-1.9,1.9l1.06,1.06l0.74-1.3c-3.52-2-6.44-4.92-8.44-8.44l-1.3,0.74l1.06,1.06l1.9-1.9
		l0.01-0.01c0.85-0.86,1.3-2,1.3-3.16c0-0.53-0.09-1.07-0.29-1.58l0,0c-0.47-1.26-0.79-2.56-0.97-3.89l0-0.01
		C13.17,3.15,11.27,1.5,9.03,1.5l-0.04,0L9,1.5H4.5l-0.07,0L4.16,1.51l-0.07,0C1.76,1.73,0,3.69,0,6c0,0.14,0.01,0.27,0.02,0.41
		l0,0.02c0.52,4.87,2.17,9.55,4.84,13.67l-0.01-0.01c2.42,3.81,5.65,7.04,9.46,9.46l-0.01-0.01c4.09,2.65,8.75,4.31,13.6,4.83
		l0.03,0c0.13,0.01,0.27,0.02,0.41,0.02h0.01h0.01c2.48-0.01,4.48-2.02,4.48-4.5l0-0.02l-0.66,0l0.66,0v0l-0.66,0l0.66,0v-4.5H31.33
		l1.5,0.04L31.33,25.38z"
    />
  </svg>

  <svg
    v-else-if="name === 'email'"
    aria-label="Email icon"
    x="0px"
    y="0px"
    viewBox="0 0 33 27"
  >
    <path
      d="M4.5,1.5V3h24h0C29.32,3,30,3.68,30,4.5v0v18v0c0,0.83-0.68,1.5-1.5,1.5h0h-24h0C3.68,24,3,23.32,3,22.5v0v-18v0
		C3,3.68,3.68,3,4.5,3h0V1.5l0-1.5C2.02,0.01,0.01,2.02,0,4.5v0v18v0c0.01,2.48,2.02,4.49,4.5,4.5h0h24h0
		c2.48-0.01,4.49-2.02,4.5-4.5v0v-18v0c-0.01-2.48-2.02-4.49-4.5-4.5h0h-24h0L4.5,1.5z"
    />
    <path
      d="M30.64,3.27l-14.14,9.9L2.36,3.27C1.68,2.8,0.75,2.96,0.27,3.64C-0.2,4.32-0.04,5.25,0.64,5.73l15,10.5
		c0.52,0.36,1.2,0.36,1.72,0l15-10.5c0.68-0.48,0.84-1.41,0.37-2.09C32.25,2.96,31.32,2.8,30.64,3.27z"
    />
  </svg>

  <svg
    v-else-if="name === 'full-screen'"
    aria-label="Full screen icon"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <path
      d="M0,0v512h512V0H0z M477.87,477.87H34.13V34.13h443.73L477.87,477.87L477.87,477.87z"
    />
    <polygon
      points="126.53,102.4 199.11,102.4 199.11,68.27 68.27,68.27 68.27,199.11 102.4,199.11 102.4,126.54 198.42,222.56 
			222.56,198.42 		"
    />
    <polygon
      points="222.56,313.58 198.42,289.45 102.4,385.47 102.4,312.89 68.27,312.89 68.27,443.73 199.11,443.73 199.11,409.6 
			126.54,409.6 		"
    />
    <polygon
      points="409.6,312.89 409.6,385.47 313.58,289.44 289.44,313.58 385.46,409.6 312.89,409.6 312.89,443.73 443.73,443.73 
			443.73,312.89 		"
    />
    <polygon
      points="312.89,68.27 312.89,102.4 385.47,102.4 289.44,198.42 313.58,222.56 409.6,126.54 409.6,199.11 443.73,199.11 
			443.73,68.27 		"
    />
  </svg>

  <svg
    v-else-if="name === 'close'"
    aria-label="Cross Icon"
    x="0px"
    y="0px"
    viewBox="0 0 64 63.6"
  >
    <path
      d="M28.9,31.8L0.6,60.1c-0.8,0.8-0.8,2.1,0,2.8C1,63.4,1.5,63.6,2,63.6c0.5,0,1-0.2,1.4-0.6L32,34.4L60.5,63 c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.8-0.8,0.8-2.1,0-2.8L35.1,31.8L63.4,3.4c0.8-0.8,0.8-2.1,0-2.8 c-0.8-0.8-2.1-0.8-2.8,0L32,29.2L3.4,0.6c-0.8-0.8-2.1-0.8-2.8,0c-0.8,0.8-0.8,2.1,0,2.8L28.9,31.8z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>