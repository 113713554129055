










import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/shared/Navigation.vue";
import Footer from "@/components/shared/Footer.vue";

@Component({
  components: {
    Navigation,
    Footer
  },
})
export default class Home extends Vue {}
