import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/pianos-for-sale',
    name: 'PianosForSale',
    component: () => import(/* webpackChunkName: "pianos-for-sale" */ '../views/PianosForSale.vue'),
    meta: {
      title: 'Pianos for sale'
    }
  },
  {
    path: '/tuning',
    name: 'Tuning',
    component: () => import(/* webpackChunkName: "tuning" */ '../views/Tuning.vue'),
    meta: {
      title: 'Tuning'
    }
  },
  {
    path: '/restoration',
    name: 'Restoration',
    component: () => import(/* webpackChunkName: "restoration" */ '../views/Restoration.vue'),
    meta: {
      title: 'Restoration'
    }
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue'),
    meta: {
      title: 'Gallery'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'Contact'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to: any, from, next) => {
  document.title = to.meta.title + ' - Cunninham Piano Services LTD'
  next()
})

export default router
