import { Module } from "vuex";

export const galleryStore: Module<any, any> = {
  namespaced: true,
  state: {
    restorationPieces: [
      {
        name: "John Broadwood and Sons Grand Piano Restoration",
        images: [
          {
            title: "Broadwood piano pre-restoration",
            src: "/dist/img/restoration/john-broadwood/before.webp",
          },
          {
            title: "Re-polished case",
            src: "/dist/img/restoration/john-broadwood/polishing.webp",
          },
          {
            title: "Removing old strings",
            src: "/dist/img/restoration/john-broadwood/inside.webp",
          },
          {
            title: "Completed restoration",
            src: "/dist/img/restoration/john-broadwood/completed.webp",
          },
        ],
      },
      {
        name: "Steinway Model A Grand Piano Restoration",
        images: [
          {
            title: "Steinway pre-restoration",
            src: "/dist/img/restoration/steinway-a/before.webp",
          },
          {
            title: "Stringing Steinway",
            src: "/dist/img/restoration/steinway-a/stringing.webp",
          },
          {
            title: "Working on hammers",
            src: "/dist/img/restoration/steinway-a/hammers.webp",
          },
          {
            title: "Hammers and framing",
            src: "/dist/img/restoration/steinway-a/hammers-and-frame.webp",
          },
          {
            title: "Frame and lettering",
            src: "/dist/img/restoration/steinway-a/frame-lettering.webp",
          },
          {
            title: "Completed Steinway",
            src: "/dist/img/restoration/steinway-a/completed.webp",
          },
        ],
      },
      {
        name: "Ronisch",
        images: [
          {
            title: "Ronisch soundboard",
            src: "/dist/img/restoration/ronisch/soundboard.webp",
          },
          {
            title: "Ronisch casework",
            src: "/dist/img/restoration/ronisch/casework.webp",
          },
        ],
      },
      {
        name: "Steinway Model K Upright",
        images: [
          {
            title: "",
            src: "/dist/img/restoration/steinway-k/1.webp",
          },
          {
            title: "",
            src: "/dist/img/restoration/steinway-k/2.webp",
          },
          {
            title: "",
            src: "/dist/img/restoration/steinway-k/3.webp",
          },
          {
            title: "",
            src: "/dist/img/restoration/steinway-k/4.webp",
          },
        ],
      },
    ]
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    restorationPieces(state: any) {
      return state.restorationPieces;
    },
  }
}