import { Module } from "vuex";

export const pianosForSaleStore: Module<any, any> = {
  namespaced: true,
  state: {
    pianos: [
      {
        name: "Ed Seiler",
        price: 3499,
        src: "/dist/img/pianos-for-sale/ed-seiler/1.webp",
        images: [
          {
            title: "",
            src: "/dist/img/pianos-for-sale/ed-seiler/1.webp",
          },
          {
            title: "",
            src: "/dist/img/pianos-for-sale/ed-seiler/2.webp",
          }
        ],
        description: "Having undergone a total restoration in my workshop, this piano is in fine condition and very good playing order. Ed Seiler pianos are one of the major respected German manufacturers of the last century and this is a typically good example. It has a very handsome rosewood case.",
        dimensions: {
          width: 150,
          height: 125,
          depth: 65
        },
        restorationPoints: [
          "full re-string",
          "full action overhaul including new hammers and dampers",
          "re-finish iron frame and soundboard"
        ]
      },
      {
        name: "Bechstein Model 10",
        price: 1400,
        src: "/dist/img/pianos-for-sale/bechstein-model-10/1.webp",
        images: [
          {
            title: "",
            src: "/dist/img/pianos-for-sale/bechstein-model-10/1.webp",
          },
        ],
        description: "Bechstein Model 10 upright piano, circa 1910. Rosewood case, straight strung model. A good example from this German maker in generally good condition inside and out. It has a musical, mellow tone and is a very nice instrument to play. A nice stylish case with original candle holders and ivory keys in good condition.",
        dimensions: {
          width: null,
          height: null,
          depth: null
        },
        restorationPoints: []
      },
      {
        name: "Young Chang",
        price: 850,
        src: "/dist/img/pianos-for-sale/young-chang/1.webp",
        images: [
          {
            title: "",
            src: "/dist/img/pianos-for-sale/young-chang/1.webp",
          },
        ],
        description: "Young Chang upright piano in satin walnut, 1997. In very good condition with a strong, vibrant tone.",
        dimensions: {
          width: 145,
          height: 120,
          depth: 58
        },
        restorationPoints: []
      },
      {
        name: "John Broadwood and Sons",
        price: 600,
        src: "/dist/img/pianos-for-sale/john-broadwood-and-sons/1.webp",
        images: [
          {
            title: "",
            src: "/dist/img/pianos-for-sale/john-broadwood-and-sons/1.webp",
          },
        ],
        description: "A black satin finished case in generally good condition. Traditional styled piano circa 1900 which plays well and sounds vibrant. Original ivory keys in very good condition.",
        dimensions: {
          width: 150,
          height: 128,
          depth: 62
        },
        restorationPoints: []
      },
    ]
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    pianos(state: any) {
      return state.pianos;
    },
  }
}