
















































































































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { gsap } from "gsap";

const contactModule = namespace("contact");
const homeModule = namespace("home");

@Component
export default class Home extends Vue {
  @contactModule.Getter iainPhoneNumber!: string;
  @contactModule.Getter bradleyPhoneNumber!: string;
  @contactModule.Getter companyName!: string;
  @contactModule.Getter yearFound!: number;
  @homeModule.Getter clients!: Array<string>;

  displayContent = gsap.timeline();
  animate() {
    this.displayContent.play();
  }
  mounted() {
    this.displayContent
      .from("header .heading-details > *", {
        duration: 0.5,
        opacity: 0,
        x: -25,
        stagger: 0.25,
        delay: 0.5,
      })
      .from("header img", {
        duration: 0.5,
        opacity: 0,
        x: 25,
      })
      .from("main .has-quote", {
        duration: 0.5,
        opacity: 0,
        y: -25,
      })
      .from("main .overview", {
        duration: 0.5,
        opacity: 0,
      })
      .from(".has-clients", {
        duration: 0.5,
        opacity: 0,
      });
    this.animate();
  }
}
