










































import { namespace } from "vuex-class";
import { Component, Vue, Watch } from "vue-property-decorator";

const contactModule = namespace("contact");

@Component
export default class Navigation extends Vue {
  @contactModule.Getter companyName!: string;

  navigationItems = [
    {
      title: "Pianos for sale",
    },
    {
      title: "Tuning",
    },
    {
      title: "Restoration",
    },
    {
      title: "Gallery",
    },
    {
      title: "Contact",
    },
  ];

  breakpoint = 1000;
  windowWidth = 0;
  mobileNavigationOpen = false;

  mounted(): void {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  toggleMobileNavigation(): void {
    this.mobileNavigationOpen = !this.mobileNavigationOpen;
  }

  handleResize(): void {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth > this.breakpoint) {
      this.mobileNavigationOpen = false;
    }
  }

  @Watch("$route")
  on$route() {
    this.mobileNavigationOpen = false;
  }
}
