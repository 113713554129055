









































import { namespace } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

const contactModule = namespace("contact");

@Component
export default class Footer extends Vue {
  @contactModule.Getter companyName!: string;
  @contactModule.Getter iainPhoneNumber!: string;
  @contactModule.Getter emailAddress!: string;
  @contactModule.Getter companyNumber!: number;
  @contactModule.Getter socials!: Array<string>;
}
