import { Module } from "vuex";

export const homeStore: Module<any, any> = {
    namespaced: true,
    state: {
        introduction: "",
        customerQuote: {
            user: "",
            info: "",
        },
        clients: [
            "University of York Music Department",
            "Harrogate International Festival",
            "Ilkley Concert Club",
            "Skipton Music Society",
            "Harrogate Ladies College",
            "Ashville College",
            "Bradford Theatres Cathedral Concerts",
            "ITV Yorkshire Television",
            "BBC",
            "Besbrode Pianos"
          ]
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        introduction(state: any) {
            return state.introduction;
        },
        customerQuote(state: any) {
            return state.customerQuote;
        },
        clients(state: any) {
            return state.clients;
        }
    }
}