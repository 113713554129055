import Vue from "vue";
import Vuex from "vuex";
import { contactStore } from "@/store/contact-store";
import { clientsStore } from "./clients-store";
import { homeStore } from "./home-store";
import { galleryStore } from "./gallery-store";
import { pianosForSaleStore } from "./pianos-for-sale-store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    contact: contactStore,
    clients: clientsStore,
    home: homeStore,
    gallery: galleryStore,
    pianosForSale: pianosForSaleStore,
  },
});