import { Module } from "vuex";

export const clientsStore: Module<any, any> = {
  namespaced: true,
  state: {
    clients: [
      "University of York Music Department",
      "Harrogate International Festival",
      "Ilkley Concert Club",
      "Skipton Music Society",
      "Harrogate Ladies College",
      "Ashville College",
      "Bradford Theatres/Cathedral concerts",
      "Yorkshire Television",
      "BBC",
      "Besbrode Pianos"
    ]
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    clients(state: any) {
      return state.companyName;
    },
  }
}